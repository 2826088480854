<template>

  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Cookies Policy</h1>


      <p class="mt-6 max-w-3xl text-xl text-indigo-100">What is a cookie?</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">A "cookie" refers to a small piece of data stored on your computer's hard drive, which records your navigation on a website. When you revisit the website, it presents customized options based on the information from your last visit. Cookies can also analyze web traffic and be utilized for advertising and marketing purposes.</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">Almost all websites use cookies, which do not damage your system.</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">You can usually modify or verify the types of cookies you accept within your browser settings. You can block cookies anytime by activating the setting on your browser that permits you to refuse all or some cookies. However, blocking all cookies (including essential cookies) might prevent you from accessing all or parts of our site.</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">How do we use cookies?</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">We employ cookies to monitor your use of our website. This helps us understand your usage patterns, allowing us to enhance our website, products, and/or services based on your needs and preferences.</p>
<ol class="list-decimal list-inside space-y-2 py-5 pl-5">
  <li class="max-w-3xl text-xm text-indigo-100">Session cookies: These cookies are stored on your computer only during your web session and are automatically deleted when you close your browser. They usually store an anonymous session ID, enabling you to browse a website without logging in to each page. These cookies do not collect any personal data from your computer.</li>
  <li class="max-w-3xl text-xm text-indigo-100">Persistent cookies: These cookies remain on your computer even after closing your web browser. The website that created the cookie can read it when you revisit that website. We use persistent cookies for Google Analytics.</li>
</ol>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">Cookies can also be categorized as:</p>
<ol class="list-decimal list-inside space-y-2 py-5 pl-5">
  <li class="max-w-3xl text-xm text-indigo-100">Strictly necessary cookies: These cookies are vital for using the website effectively, such as when purchasing a product and/or service, and cannot be turned off. Without these cookies, the services available on our website cannot be provided. They do not collect information that could be used for marketing or tracking your internet history.</li>
  <li class="max-w-3xl text-xm text-indigo-100">Performance cookies: These cookies help us monitor and enhance the performance of our website. They enable us to count visits, identify traffic sources, and determine the most popular sections of the site.</li>
  <li class="max-w-3xl text-xm text-indigo-100">Functionality cookies: These cookies allow our website to remember your choices and offer improved features. For example, they may enable us to provide news or updates relevant to the services you use. They can also be used for services you have requested, such as watching a video or commenting on a blog. The information collected by these cookies is typically anonymized.</li>
</ol>
  <p class="mt-6 max-w-3xl text-xl text-indigo-100">Please note that third parties who advertise on our website (including, for instance, advertising networks and external service providers like web traffic analysis services) might also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
    
    </div>
  </div>

  </template>

<style>
.opacity-50 {
    opacity: 0.4;
}
</style>
    
    <script>
    export default {
      name: 'CookiesPolicy',
      metaInfo() {
        return {
          title: 'Cookies Policy - ASAP House Buyers',
          meta: [
            {
              name: 'description',
              content: 'Cookies Policy',
            },
            {
              name: 'keywords',
              content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
            },
          ],
        };
      },
    };
    </script>
  
    