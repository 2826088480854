<template>
  <div id="quiz-form" class='bg-white shadow-md rounded p-8' ref='quizForm'>
    <div v-if='currentQuestionIndex < questions.length'>
      <div class='flex items-center justify-between mb-1'>
        <div class='text-sm font-bold'>
          Step {{ currentQuestionIndex + 1 }} of {{ questions.length }}
        </div>
      </div>
      <div class='border-b-2 border-gray-200 mb-2'></div>
      <div class='text-xl mb-4 text-blue-500 font-semibold text-center px-2'>
        {{ questions[currentQuestionIndex].question }}
      </div>
      <select
        v-model='selectedAnswer'
        class='custom-dropdown group hover:bg-blue-200 cursor-pointer bg-gray-200 w-full rounded px-2 py-3 flex items-center mb-2'
      >
        <option value='' disabled>Select an option...</option>
        <option
          v-for='(answer, answerIndex) in questions[currentQuestionIndex].answers'
          :key='answerIndex'
          :value='answer'
        >{{ answer.text }}</option>
      </select>
      <p class='text-gray-500 flex items-center p-0 text-right pt-2'>
        <i class='text-green-600 fas fa-shield-alt mr-1'></i>
        Safe, secure &amp; confidential
      </p>
    </div>
    <div v-else>
      <div class="spinner" v-if="loading">
        <div class="circle"></div>
        <p class="loading-text">Checking your property... <br>Please wait...</p>
      </div>
      <ContactForm v-else />
</div>
  </div>
</template>

<style>
.custom-dropdown {
  border: 1px solid black;
  appearance: none;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 8px solid #ccc;
  border-top-color: #888;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
</style>

<script>
import ContactForm from '../components/ContactForm.vue';

export default {
  name: "QuizForm",
  components: {
    ContactForm
  },
  data() {
    return {
      questions: [
        {
          question: 'Are you currently already on the market?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
          ],
        },
        {
          question: 'What type of property are you selling?',
          answers: [
            { text: 'Detached' },
            { text: 'Flat / Apartment' },
            { text: 'Semi-Detached' },
            { text: 'Mid-Terrace' },
            { text: 'End-Terrace' },
            { text: 'Bungalow' },
            { text: 'Land' },
            { text: 'Lower Cottage Flat' },
            { text: 'Upper Cottage Flat' },
            { text: 'Commercial Property' },
            { text: 'Other' },
          ],
        },
        {
          question: 'How many bedrooms?',
          answers: [
            { text: '1' },
            { text: '2' },
            { text: '3' },
            { text: '4' },
            { text: '5' },
            { text: '6+' },
          ],
        },
        {
          question: 'Reason for sale?',
          answers: [
            { text: 'Inherited' },
            { text: 'Financial' },
            { text: 'Matrimonial' },
            { text: 'Downgrading' },
            { text: 'Relocation' },
            { text: 'Ex-Rental' },
            { text: 'Health Reasons' },
            { text: 'Upgrading' },
            { text: 'Empty Property' },
            { text: 'Emigrating' },
            { text: 'Moving in with family and friends' },
            { text: 'Repossession' },
            { text: 'Other' },
          ],
        },
        {
          question: 'How fast do you need to sell?',
          answers: [
            { text: 'ASAP' },
            { text: '7 Days' },
            { text: '1 - 4 Weeks' },
            { text: '5 Weeks Plus' },
          //{ text: 'No hurry at all' },
          ],
        },
      ],
      currentQuestionIndex: 0,
      answers: [],
      selectedAnswer: '',
      loading: false,
      showForm: false, // Add this line
    };
  },
  watch: {
    selectedAnswer(answer) {
      if (answer) {
        this.answers.push(answer);
        this.selectedAnswer = "";
        this.currentQuestionIndex++;

        if (this.currentQuestionIndex >= this.questions.length) {
          console.log("All questions answered. Submitting quiz...");
          this.loading = true;
          console.log("Loading set to true. Spinner should be visible.");

          setTimeout(() => {
            this.submitQuiz();
            console.log("Form submitted. Waiting for response...");
          }, 4000); // Delay the form submission for 2 seconds

          setTimeout(() => {
            this.loading = false;
            console.log("Loading set to false. Spinner should disappear.");
          }, 5000); // Hide the spinner after 5 seconds
        }
      }
    }
  },


  methods: {
    scrollToTop() {
      this.$refs.quizForm.scrollIntoView({ behavior: "smooth", block: "start", inline: "start", scrollBehavior: "auto" });
    },
    constructQuizData() {
      return {
        apiParam1: this.answers[0].text,
        apiParam2: this.answers[1].text,
        apiParam3: this.answers[2].text,
        apiParam4: this.answers[3].text,
        apiParam5: this.answers[4].text,
      };
    },
    submitQuiz() {
      const quizData = this.constructQuizData();
      this.$emit('completed', quizData);
    },
  },
  mounted() {
    this.scrollToTop();
  },
};
</script>
