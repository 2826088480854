<template>
    <div class="more-articles">
      <h2 class="text-2xl font-bold mb-4">More Articles</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div v-for="article in articles" :key="article.id" class="rounded-lg overflow-hidden shadow-md">
          <img :src="getImageSrc(article.Content)" alt="Article thumbnail" class="w-full h-40 object-cover">
          <div class="p-4 bg-white">
            <h3 class="text-lg font-bold mb-2">{{ article.Title }}</h3>
            <a :href="`/articles/${article.slug}`" class="text-green-500 hover:text-green-600">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        articles: [],
      };
    },
    methods: {
      getImageSrc(htmlString) {
        const parser = new DOMParser();
        const html = parser.parseFromString(htmlString, 'text/html');
        const img = html.querySelector('img');
        return img ? img.src : '';
      }
    },
    async mounted() {
      const response = await axios.get(
        'https://blog.asaphousebuyers.co.uk/api/news-articles?filters[Domain][$eq]=sellhousefastscotland.co.uk'
      );
      this.articles = response.data.data.map((article) => article.attributes);
    },
  };
  </script>
  
  
  