<template>

<div class="relative bg-indigo-800">
  <div class="absolute inset-0">
    <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-contact.jpg" alt="">
    <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
  </div>
  <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
    <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Contact Us</h1>
    <p class="mt-6 max-w-3xl text-xl text-indigo-100">We can be contacted via the following details:</p>
    
    <div class="flex flex-col mt-4 text-left">
      <div class="text-2xl font-semibold text-indigo-200 mb-2">
        <i class="fas fa-phone mr-2"></i> Phone
      </div>
      <div class="text-xl text-white mb-6">
        <a href="tel:03333558956" class="text-white">0333 355 8956</a>
      </div>


      <div class="text-2xl font-semibold text-indigo-200 mb-2">
        <i class="fas fa-envelope mr-2"></i> Email
      </div>
      <div class="text-xl text-white mb-6">
        info@sellhousefastscotland.co.uk
      </div>
    </div>

    <a href="/#addressForm" class="bg-green-500 hover:bg-green-600 text-white font-bold text-center py-2 px-4 rounded shadow-lg mt-6 mx-auto text-xl block max-w-xs">Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i></a>

  </div>
</div>


</template>

<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>

  
export default {
    name: 'ContactUs',
    metaInfo() {
      return {
        title: 'Contact Us | Sell House Fast Scotland',
        meta: [
          {
            name: 'description',
            content: 'All about Sell House Fast Scotland - Get a Free Cash Offer Today - A Team with 30 years experience.',
          },
          {
            name: 'keywords',
            content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
          },
        ],
      };
    },
  };
</script>
  

  