<template>
  <div class="countdown-wrapper">
    <p class="countdown-message">We Could Buy Your House in:</p>
  <div class="countdown-container">
    <div class="countdown-unit">
      <div class="countdown-number">{{ days }}</div>
      <div class="countdown-label">Days</div>
    </div>
    <div class="countdown-unit">
      <div class="countdown-number">{{ hours }}</div>
      <div class="countdown-label">Hours</div>
    </div>
    <div class="countdown-unit">
      <div class="countdown-number">{{ minutes }}</div>
      <div class="countdown-label">Minutes</div>
    </div>
    <div class="countdown-unit">
      <div class="countdown-number">{{ seconds }}</div>
      <div class="countdown-label">Seconds</div>
    </div>
   </div>
  </div>
</template>

  
  <script>
  export default {
    name: "CountdownTimer", // Change the component name here
    data() {
      return {
        endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    },
    created() {
      this.updateCountdown();
      setInterval(() => {
        this.updateCountdown();
      }, 1000);
    },
    methods: {
      updateCountdown() {
        const now = new Date();
        const remainingTime = this.endDate - now;
  
        this.days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      },
    },
  };
  </script>
  
  <style scoped>
  .countdown-wrapper {
    text-align: center;
    padding-top:0px;
  }
  
  .countdown-message {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .countdown-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 2px solid red;
    color: red;
    font-weight: bold;
    font-size: 0.5rem;
    width: 70px;
    height: 70px;
    margin: 0 10px;
  }
  
  .countdown-number {
    font-size: 1.8rem; /* Adjust the font size here */
  }
  
  .countdown-label {
    text-transform: uppercase;
  }
  </style>
  
  
  
  