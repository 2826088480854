<template>

    <div class="relative bg-indigo-800">
      <div class="absolute inset-0">
        <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
      </div>
      <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Privacy Policy</h1>
        
        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> At Sell House Fast Scotland, we are committed to protecting your privacy and ensuring the consistent treatment of your personal data. 
        This privacy policy outlines how we collect, process, and safeguard your personal information when you use our website or engage with our services, including requesting a cash offer, completing a contact form, or participating in a competition.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> Please note that our website is not intended for children, and we do not knowingly collect data relating to children.</p>

            <p class="mt-6 max-w-3xl text-xm text-indigo-100"> Consent – The Information We Collect
        When you request a cash offer or information regarding our property services, we require your name, email address, telephone number, and property details.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> We collect this information to effectively handle your enquiry, process any subsequent offer and purchase, and communicate with you regarding our services and any other relevant matters.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> We may collect, use, store, and transfer different types of personal data about you, which we have grouped together as follows:</p>
        
        
        <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
            <li class="max-w-3xl text-xm text-indigo-100">
                Identity Data: Includes first name, maiden name, last name or similar identifier, marital status, title, date of birth, and gender.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                Contact Data: Includes postal address, email address, and telephone numbers.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                Profile Data: Includes orders made by you, your interests, preferences, feedback, and survey responses.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                Technical Data: Includes GA Client ID, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.
            </li>
            <li class="tmax-w-3xl text-xm text-indigo-100">
                Usage Data: Includes information about how you use our website.
            </li>
        </ol>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">We also collect and use Aggregated Data, such as statistical or demographic data, for various purposes. 
        Aggregated data may be derived from your personal data but is not considered personal data in law as it does not directly or indirectly reveal your identity.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">How We Use Your Personal Data</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">To provide our services, it may be necessary to share a limited amount of your personal contact information with third parties, such as solicitors, surveyors, and estate agents, solely to facilitate the service you have requested.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">We will never pass your details onto an irrelevant third party. Your data will only be used to facilitate your cash offer and/or provide you with information and special offers related to our services.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">We will use your personal data only when the law permits us to do so. Commonly, we will use your personal data in the following circumstances:</p>



        <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
            <li class="max-w-3xl text-xm text-indigo-100">
                To perform the contract we have entered into, or are about to enter into, with you.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                To meet our legitimate interests, as long as your interests and fundamental rights do not override those interests.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                To comply with legal or regulatory obligations.
            </li>
        </ol>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Data Retention – How Long We Keep Your Data</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Sell House Fast Scotland will retain your personal data for as long as you are a customer of our company, plus an additional 6 years, subject to any overriding legal or regulatory requirements.</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">As a customer of [Your Company Name], you have the right to be forgotten. If you would like your personal data to be removed from our system, you can do so by making a written request to our data officer at:</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Sell House Fast Scotland - Suite 2/3 48 West George Street, Glasgow, G2 1BP.</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Email: info@investinscottishproperties.co.uk</p>


        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Data Subject Rights – Your Rights to Access Personal Information</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">In accordance with GDPR, you have the right to request a copy of the personal information held by Sell House Fast Scotland and to request that any inaccurate data be corrected or removed.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">As a customer of Sell House Fast Scotland, your personal rights include:</p>

        <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
            <li class="max-w-3xl text-xm text-indigo-100">
                The right to be informed about what information we hold and the purpose for processing your personal data.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                The right to access and rectify any personal data held about you.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                The right to request that your personal data be erased from our system.
            </li>
        </ol>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Upon written request to our Data Protection Officer, we will provide a statement regarding the personal data held about you.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Changes to the Privacy Policy and Your Duty to Inform Us of Changes to Your Details
        If any of the information that you have provided to Sell House Fast Scotland changes, such as your email address, telephone number, or 
        name, please let us know by sending an email to info@investinscottishproperties.co.uk or by sending a letter to:</p>
        
                
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Sell House Fast Scotland - Suite 2/3 48 West George Street, Glasgow, G2 1BP.</p>


        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Cookies</p>
        
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        We use a variety of cookies on our site to enhance user experience and analyze site usage. 
        If you are unfamiliar with cookies or how to control or delete them, we recommend reviewing detailed guidance <a href="https://www.aboutcookies.org/">here</a>.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        By using our site, you agree to our 'implied consent' cookie policy, which assumes that you are content with this usage. 
        If you are not happy with this policy, you can either discontinue using our site or delete our cookies after visiting the site. 
        You can also browse our site using your browser's anonymous usage setting – such as 'Incognito' in Chrome, 'InPrivate' for Internet Explorer, or 'Private Browsing' in Firefox and Safari.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        For a description of the cookies we use on our site and their purposes, please review the section on cookies in our privacy policy.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        How We Protect Your Information</p>

       <p class="mt-6 max-w-3xl text-xm text-indigo-100"> The internet is not a completely secure medium. However, we have implemented various security measures, 
        including firewalls to prevent unauthorized external access to our systems and data. We also keep your information confidential, 
        and our internal procedures cover the storage, access, and disclosure of your information. Access to visitor information is limited, and all files are password protected.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Telephone Calls – Calls may be recorded for training and monitoring purposes. Any call recordings are held according to our data security procedures.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Personal Data Breaches and Complaints</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">If you believe your personal data has been misused or that we have not kept your personal details secure, you should contact our Data Protection Officer to log a complaint.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">If you are dissatisfied with our response or require further advice, you may contact the Information Commissioner's Office (ICO).</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Company Registration Details<br />
        Invest in Scottish Properties trading as  Sell House Fast Scotland<br />
       </p>

       
       <p class="mt-6 max-w-3xl text-xm text-indigo-100">Suite 2/3 48 West George Street, Glasgow, G2 1BP.</p>
        
      </div>
    </div>
    
    </template>
      
      <script>
    
      
      export default {
          name: 'PrivacyPolicy',
          metaInfo() {
            return {
              title: 'Privacy Policy | Sell House Fast Scotland',
              meta: [
                {
                  name: 'description',
                  content: 'Privacy Policy Sell House Fast Scotland Works - Get a Free Cash Offer Today - A Team with 30 years experience.',
                },
                {
                  name: 'keywords',
                  content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
                },
              ],
            };
          },
        };
      </script>
      
    
      