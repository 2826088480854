<template>

    <div class="relative bg-indigo-800">
      <div class="absolute inset-0">
        <img class="h-full w-full object-cover opacity-50" src="@/assets/how-we-work.jpg" alt="How ASAP House Buyers Works">
        <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
      </div>
      <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">How We Work</h1>
        
        <p class="mt-6 max-w-3xl text-xl text-indigo-100"> Discover the Joy of a Hassle-Free Cash Property Sale with Us 🏠💰</p>

        <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl mr-4 lg:text-2xl pt-4"> Our Reach</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        Alright, here’s the craic! We’ve been in the business of snapping up properties for cash for yonks, and let me tell you, we know our stuff. We’ve bought, fixed up, rented, and sold more homes than you can shake a stick at, earning ourselves a solid reputation as the folks you can trust. 🏠💰</p>

        
        <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl mr-4 lg:text-2xl pt-4"> A Smooth Ride from Start to Finish</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
          When you decide to team up with us, we promise a ride smoother than a dram of single malt. First off, we’ll take a gander at your property and give you a cash offer that’s as solid as a rock. Say aye to the offer, and we’ll get the ball rolling, putting everything in writing through our solicitors – nae dodgy dealings here, just good old-fashioned honesty.</p>
        
          <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl mr-4 lg:text-2xl pt-4"> We Got You Covered</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
          We’re not just about buying your home; we’re about making it easy-peasy for you. That’s why we’ll cover your conveyancing costs – that’s right, no hidden fees lurking in the shadows. And here’s the best bit: you call the shots on when you hand over the keys. Need the cash pronto? We can wrap things up in just 7 days. Need a bit more time to say your goodbyes? We’re happy to wait.</p>

          <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl mr-4 lg:text-2xl pt-4"> Why Choose Us</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
          Choosing us means choosing a straightforward, trustworthy, and hassle-free experience. We’re here to take the stress out of selling your home, leaving you free to enjoy the next chapter of your life. So, what are you waiting for? Let’s get this show on the road!</p>

       <a href="/#addressForm" class="bg-green-500 hover:bg-green-600 text-white font-bold text-center py-2 px-4 rounded shadow-lg mt-6 mx-auto text-xl block max-w-xs">Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i></a>

    
    
      </div>
    </div>
    
    </template>
    <style>
    .opacity-50 {
        opacity: 0.3;
    }
    </style>

      
      <script>
      export default {
          name: 'HowWeWork',
          metaInfo() {
            return {
              title: 'How We Work | Sell House Fast Scotland',
              meta: [
                {
                  name: 'description',
                  content: 'How Sell House Fast Scotland Works - Get a Free Cash Offer Today - A Team with 30 years experience.',
                },
                {
                  name: 'keywords',
                  content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
                },
              ],
            };
          },
        };
      </script>
      
    
      