
<template>
  <div class="w-full max-w-xs sm:max-w-md mx-auto py-2 px-4 rounded-lg">
    <div class="bg-green-500 text-white px-4 py-2 rounded-t-md text-center font-bold text-xl">
      Get a Free Property Cash Offer
    </div>
    <div class="bg-green-500 text-white px-4 py-1 text-center font-semibold text-sm">
      No-Obligation - Instant Decision<br>Get a Quick Quote Now
    </div>
    <form @submit.prevent="searchAddress" class="bg-white shadow-md rounded-b-md px-6 sm:px-8 pt-6 pb-4">
      <div class="mb-4">
        <label class="block text-gray-700 font-bold mb-2 text-lg" for="postcode">
          Get Started...
        </label>
        <div class="flex">
          <input v-model="postcode" @input="handlePostcodeInput" class="mobile-input rounded-full bg-gray-200 border-2 border-blue-300 w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 text-lg" id="postcode" type="text" placeholder="Enter your Postcode..." />
          <button class="submit-btn bg-green-500 hover:bg-green-600 text-white px-6 rounded-full font-bold transition duration-300 ease-in-out transform hover:scale-105" type="submit">GO</button>
        </div>
        
        <div class="relative mt-2" v-click-outside="closeSuggestions">
          <!-- Suggestions container -->
          <ul v-if="addressSuggestions.length > 0 && suggestionsVisible" class="absolute z-10 bg-white rounded shadow-lg text-gray-700 max-h-64 overflow-y-auto">
            <!-- List of suggestions -->
            <li v-for="suggestion in addressSuggestions" :key="suggestion.id" class="py-2 px-3 hover:bg-gray-200 cursor-pointer autocomplete-text" @click="selectAddress(suggestion)">
              {{ suggestion.address }}
            </li>
          </ul>
        </div>



        <div v-if="submitted && !address" class="text-red-500 text-lg mt-2">Please enter a postcode</div>
      </div>
      <div class="review-section border-t-2 border-gray-200 mt-6 pt-6">
        <div class="flex flex-col items-center justify-center">
          <p class="review-text text-gray-600 italic text-center text-sm sm:text-lg">{{ reviewText }}</p>
          <div class="star-rating-logo-wrapper flex flex-col items-center justify-center mt-0">
            <div class="star-rating text-yellow-400 text-2xl">
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div>
            <img :src="require('@/assets/rfline.png')" alt="Referenceline Logo" class="h-10 mt-0" />
          </div>
          <p class="reviewer-info text-gray-500 text-lg mt-2 text-center">{{ reviewerName }}</p>
        </div>
      </div>
      <div class="trust-logos mt-4">
        <img src="@/assets/trust-logos.png" alt="Trust Logos" class="mx-auto" style="width: 100%; max-width: 300px; height: auto;">
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  directives: {
  clickOutside: {
    bind(el, binding, vnode) {
      el.clickOutsideHandler = function(event) {
        if (!el.contains(event.target)) {
          vnode.context[binding.expression](event);
        }
      };
      document.addEventListener('click', el.clickOutsideHandler);
    },
    unbind(el) {
      document.removeEventListener('click', el.clickOutsideHandler);
    },
  },
},
  data() {
    return {
      postcode: "",
      addressSuggestions: [],
      submitted: false,
      addressDetailsCache: {},
      suggestionsVisible: false, // Track visibility of suggestions
    };
  },
  mounted() {
    // Initialize the debounced search method
    this.debounceSearch = this.debounce(this.searchAddress, 500);
  },
  methods: {
    async searchAddress() {
    // Check if the postcode is valid before making the API call
    if (this.isValidPostcode(this.postcode)) {
      try {
        const postcodeWithoutSpaces = this.postcode.replace(/\s+/g, '');
        const response = await axios.get(`https://api.getAddress.io/autocomplete/${postcodeWithoutSpaces}?api-key=dtoken_hEDzcyiWMr0067R_6K7Dj85dIiYE3omWyWBBgeqmtrp6mJd27zSBIo1eyhADhPZyqh-3OOe5ZYEJGnpN7U3e28OZ2EVLyrSu4tQpoYfDbO_f2tg4TYXVobWM3SmxM228eG6JxkyD733xm4k6Bj-gTLGTcDDj9mszjz8K75TNioZWyOki_Z7zxaH5_5EpGU12uwNUIbYSBjvHB6HkSmUezzKIIu0dkTKW&all=true`);
        this.addressSuggestions = response.data.suggestions;
        // Show suggestions when data is available
        this.suggestionsVisible = true;
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    } else {
      // Close suggestions by setting suggestionsVisible to false
      this.suggestionsVisible = false;
    }
  },

  closeSuggestions() {
    this.suggestionsVisible = false; // Hide suggestions
  },
    debounce(func, delay) {
      let debounceTimer;
      return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    },
    isValidPostcode(postcode) {
      const regex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
      return regex.test(postcode);
    },
    async selectAddress(suggestion) {
      try {
        const response = await axios.get(`https://api.getAddress.io/get/${suggestion.id}?api-key=dtoken_hEDzcyiWMr0067R_6K7Dj85dIiYE3omWyWBBgeqmtrp6mJd27zSBIo1eyhADhPZyqh-3OOe5ZYEJGnpN7U3e28OZ2EVLyrSu4tQpoYfDbO_f2tg4TYXVobWM3SmxM228eG6JxkyD733xm4k6Bj-gTLGTcDDj9mszjz8K75TNioZWyOki_Z7zxaH5_5EpGU12uwNUIbYSBjvHB6HkSmUezzKIIu0dkTKW&all=true`);
        const { formatted_address, postcode, country } = response.data;

        // Clean up the address by removing unnecessary commas
        const cleanedAddress = formatted_address.filter(part => part.trim() !== '').join(', ');

        this.$emit("addressSelected", { street: cleanedAddress, postcode: postcode, country: country });
        this.postcode = `${cleanedAddress}, ${postcode}`;
        this.addressSuggestions = [];
        this.proceedToQuiz();
      } catch (error) {
        console.error("Error selecting address:", error);
      }
    },
    proceedToQuiz() {
      this.$emit('proceedToQuiz');
      window.scrollTo({
      top: 500,
      left: 0,
      behavior: 'smooth'
    });
  },
},
  watch: {
    postcode() {
      // Trigger debounced search method on postcode change
      this.debounceSearch();
    },
  },
  computed: {
    showSuggestions() {
      return this.addressSuggestions.length > 0 && this.suggestionsVisible;
    },
    reviewText() {
      if (this.$route.path.startsWith('/financial/')) {
        return "From start to finish they clearly explained everything in detail i needed to know kept in touch with emails fone calls n txts they took care of every little detail and made my sale pass with ease i recommend them 100% for a quick sale it was a cash buy and the money was in my bank in days";
      } else {
        return "From start to finish they clearly explained everything in detail i needed to know kept in touch with emails fone calls n txts they took care of every little detail and made my sale pass with ease i recommend them 100% for a quick sale it was a cash buy and the money was in my bank in days";
      }
    },
    reviewerName() {
      if (this.$route.path.startsWith('/financial/')) {
        return "- Happy Seller";
      } else {
        return "Happy Seller";
      }
    }
  }
};
</script>




<style scoped>
/* Base styles for the form container */
.w-full {
  max-width: 100%;
}

/* Padding for better spacing on all devices */
.py-6 {
  padding: 2rem 1rem;
}

/* Form header styles */
.bg-green-500 {
  background-color: #38a169; /* Example green color */
}

.text-white {
  color: white;
}

/* Postcode input and button styles */
.mobile-input,
.submit-btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 9999px; /* Full roundness */
}

/* Button-specific styles */
.submit-btn {
  background-color: #48bb78; /* Example green color for the button */
  color: white;
  margin-left: 0.5rem; /* Space between the input and button */
}

/* Review section styles */
.review-section {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem; /* Increased padding for larger quotes */
  margin-top: 2rem;
  border-top: 2px solid #e2e8f0;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Pseudo-elements for quotes */
.review-section:before,
.review-section:after {
  color: #cbd5e0; /* Light color for the quote icon */
  position: absolute;
  font-family: "Times New Roman", Times, serif;
  z-index: 0;
}

.review-section:before {
  content: '“';
  top: 0;
  left: 0.5rem;
  font-size: 4em;
  line-height: 1;
}

.review-section:after {
  content: '”';
  bottom: 0;
  right: 0.5rem;
  font-size: 4em;
  line-height: 1;
}

/* Text styles for review and rating */
.review-text {
  font-style: italic;
  color: #4a5568; /* Darker color for contrast */
  line-height: 1.6; /* Adjust line height for better readability */
  max-width: 925px; /* Maximum width of the review text block */
  margin: auto; /* Center the text block */
}

/* Star rating styles */
.star-rating {
  font-size: 1.25rem;
  color: #ecc94b; /* Gold color for stars */
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  margin-top: 1rem; /* Add space above the star rating */
}

.reviewer-info {
  font-size: 0.9rem; /* Smaller font size for meta information */
  color: #718096; /* Muted color for less emphasis */
  text-align: right; /* Align to the right */
  position: relative;
  z-index: 1;
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  /* Adjust the main container padding */
  .w-full.max-w-xs.sm\:max-w-md.mx-auto.py-2.px-4.rounded-lg {
    padding: 1rem; /* Consistent padding on mobile */
  }
  .review-text {
    /* Adjust maximum width for smaller screens */
    max-width: 100%;
    //padding: 0 1rem; /* Add padding on small screens */
  }

  /* Adjust the form's overall padding to be consistent */
  .bg-white.shadow-md.rounded-b-md.px-6.sm\:px-8.pt-6.pb-4 {
    padding: 1rem; /* Adds padding inside the form */
  }

  /* Styles for input and button to ensure they do not touch the container edges */
  .mobile-input {
    flex-grow: 1; /* Input will grow to take available space */
    max-width: calc(100% - 32px); /* Adjust based on the main container's padding */
  }

  .submit-btn {
    flex-shrink: 0; /* Button will not shrink */
    width: 50px; /* Set a fixed width for the button */
  }

  /* Adjust the review text size for better readability */
  .review-text,
  .reviewer-info,
  .star-rating i {
    font-size: 0.775rem;
  }

  /* Postcode suggestion list styles */
.absolute.z-10.bg-white.rounded.shadow-lg.text-gray-700.max-h-64.overflow-y-auto {
  border: 1px solid #e2e8f0; /* Light grey border */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #f7faff; /* Light background */
  max-height: 300px; /* Adjust the max height as needed */
}

/* Individual suggestion styles */
.py-2.px-3.hover:bg-gray-200.cursor-pointer.autocomplete-text {
  padding: 10px 15px; /* More padding for each item */
  color: #4a5568; /* Darker text color for better contrast */
}

/* Hover effect for suggestions */
.py-2.px-3.hover:bg-gray-200.cursor-pointer.autocomplete-text:hover {
  background-color: #e2e8f0; /* Light grey background on hover */
  color: #2d3748; /* Slightly darker text on hover */
  font-weight: 500; /* Slightly bolder text on hover */
}

/* Responsive adjustments */
@media only screen and (max-width: 600px) {
  /* Adjust suggestion list size on smaller screens */
  .absolute.z-20.bg-white.rounded.shadow-lg.text-gray-700.max-h-64.overflow-y-auto {
    max-width: calc(100% - 20px); /* Full width with some padding */
  }

  /* Adjust font size in suggestions for readability */
  .py-2.px-3.hover:bg-gray-200.cursor-pointer.autocomplete-text {
    font-size: 14px; /* Slightly larger text */
  }
}
}
</style>

