<template>
  <header class="bg-white shadow-md py-3 relative z-50">
    <div class="container mx-auto flex justify-between items-center">
      <div class="logo-container">
        <a href="/">  <img src="@/assets/sell-house-fast-scotland.svg" alt="Sell House Fast Scotland" class="h-16 w-auto md:h-24 lg:h-16 xl:h-16 2xl:h-16 px-1" /></a>
      </div>
      <nav class="hidden md:flex items-center space-x-6 md:relative md:bg-transparent md:shadow-none" v-show="(!isMobile || menuOpen) && !$route.path.startsWith('/articles/')">
        <a href="/" class="text-gray-800 hover:text-blue-600">HOME</a>
        <a href="/how-we-work" class="text-gray-800 hover:text-blue-600">HOW WE WORK</a>
        <router-link to="/about-us" class="text-gray-800 hover:text-blue-600">ABOUT US</router-link>
        <router-link to="/contact-us" class="text-gray-800 hover:text-blue-600">CONTACT US</router-link>
      </nav>
      <div @click.prevent="toggleMenu" class="md:hidden focus:outline-none cursor-pointer" v-if="!$route.path.startsWith('/articles/')">
        <i v-if="!menuOpen" class="fas fa-bars text-xl text-gray-800"></i>
        <i v-else class="fas fa-times text-xl text-gray-800"></i>
      </div>
      <div class="text-lg font-bold flex items-center flex-col bg-gray-200 rounded-lg p-2 sm:text-base mr-1">
        <div class="flex items-center mb-1">
          <i class="fas fa-phone text-blue-600 mr-2"></i>
          <a href="tel:03333558956" class="text-blue-600">0333 355 8956</a>
        </div>
        <span class="text-sm">24/7 Freephone</span>
      </div>
    </div>
    <div class="mobile-menu md:hidden" v-show="menuOpen">
      <nav class="bg-white shadow-md py-3 mt-1">
        <div class="container mx-auto flex flex-col space-y-3">
          <a href="/" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">HOME</a>
          <a href="/how-we-work" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">HOW WE WORK</a>
          <a href="/about-us" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">ABOUT US</a>
          <a href="/contact-us" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">CONTACT US</a>
        </div>
      </nav>
    </div>
  </header>
</template>


<script>
export default {
  name: "AppHeader",
  data() {
    return {
      menuOpen: false,
      isMobile: false,
    };
  },
  methods: {
    toggleMenu() {
      if (this.isMobile) {
        this.menuOpen = !this.menuOpen;
      }
    },
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 768;
    });
  },
};
</script>

<style>
  @media (orientation: portrait) {
    .logo-mobile-portrait {
      max-height: 2.50rem;
    }
  }
</style>
