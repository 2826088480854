<template>

  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-cash-request.jpg" alt="Sold">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl"> Thanks for contacting us</h1>

      <div class="mt-12 mx-auto w-full sm:w-1/2 lg:w-1/4 text-center">
        <img class="mx-auto rounded-full w-1/2 sm:w-1/2 lg:w-3/4 object-cover" src="@/assets/laurav2.png" alt="Laura McArthur">
        <div class="mt-4 text-lg sm:text-xl lg:text-2xl text-white font-semibold">Your Buyer - Laura McArthur</div>
      </div>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">Thanks for contacting us. Laura will be in touch shortly to get some more details on your property and discuss a cash offer.</p>


      <p class="mt-6 max-w-3xl text-xl text-indigo-100">We are a family business and have been buying property in Scotland for over 20 years. We will listen to your needs and help find the best solution for you in terms of price and timescales.</p>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">We are fully equipped with our cash funds, so if you choose to accept our offer, you can sit back and relax, knowing that there will be no last-minute issues with lending, meaning that you can confidently make plans to move on without any worry that the sale may fall through.</p>

        <p class="mt-6 max-w-3xl text-xl text-indigo-100">We have over 200 reviews from previous clients on an independent review site. Below is what a couple of recent clients have had to say about us</p>
      




        <div class="flex flex-col mt-4 text-left">
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
  <a href="https://api.whatsapp.com/send?phone=447432074424" target="_blank">
    <i class="fab fa-whatsapp mr-2"></i> WhatsApp
  </a>
</div>
<div class="text-xl text-white mb-6">
  Click to message us on WhatsApp
</div>


        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Phone
        </div>
        <div class="text-xl text-white mb-6">
          0333 355 8956
        </div>
  
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Email
        </div>
        <div class="text-xl text-white mb-6">
          info@sellhousefastscotland.co.uk
        </div>
      </div>
    </div>
  </div>
  
  </template>
    
<script>
export default {
  name: 'ThankYou',
  metaInfo() {
    return {
      title: 'Thank You - Sell House Fast Scotland',
      meta: [
        {
          name: 'description',
          content: 'Thank you for submitting your property details for a free cash offer! Our friendly and professional property consultant will be in touch with you shortly.',
        },
        {
          name: 'keywords',
          content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
        },
      ],
    };
  },
};
</script>

<style>
.opacity-50 {
  opacity: 0.4;
}
</style>

<style>
.opacity-50 {
    opacity: 0.4;
}
</style>

    
  
    