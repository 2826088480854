<template>
  <div class="container mx-auto mt-0 p-2 bg-gray-100 rounded-md shadow-lg w-full max-w-xs sm:max-w-sm">
  <div class="bg-gray-100 p-5 rounded-md">
 


    <h2 class="text-xl sm:text-2xl font-extrabold mb-1">Great News!</h2>
    <p class="text-gray-800 text-sm md:text-base mb-2">
      We'd like to make a cash offer on your property.
      Please enter your details below.
    </p>

    <form id="request-cash-offer" @submit.prevent="submitForm">
<div class="mb-4">
<input
class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:py-4 md:px-4 md:text-lg"
  id="firstName"
  type="text"
  v-model="firstName"
  placeholder="First Name"
  @blur="validateFirstName"
/>
<p class="text-red-500 text-xs italic" v-if="firstNameError">{{ firstNameError }}</p>
</div>

<div class="mb-4">
<input
class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:py-4 md:px-4 md:text-lg"
  id="lastName"
  type="text"
  v-model="lastName"
  placeholder="Last Name"
  @blur="validateLastName"
/>
<p class="text-red-500 text-xs italic" v-if="lastNameError">{{ lastNameError }}</p>
</div>

<div class="mb-4">
<input
class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:py-4 md:px-4 md:text-lg"
  id="email"
  type="text"
  v-model="email"
  placeholder="Email"
  @blur="validateEmail"
/>
<p class="text-red-500 text-xs italic" v-if="emailError">{{ emailError }}</p>
</div>

<div class="mb-4">
<input
class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:py-4 md:px-4 md:text-lg"
  id="phone"
  type="tel"
  v-model="phone"
  placeholder="Contact Number"
  @blur="validatePhone"
/>
<p class="block text-xs font-medium text-gray-500 small-device-text">We need a valid number to make an offer</p>
<p class="text-red-500 text-xs italic" v-if="phoneError">{{ phoneError }}</p>
</div>
    <div class="mb-4">
      <label class="inline-flex items-center">
        <input
          type="checkbox"
          class="form-checkbox text-green-500"
          v-model="privacyPolicy"
        />
        <span class="ml-2">I agree to the <a href="/privacy-policy" class="text-blue-500" target="_blank">Privacy Policy</a>.</span>
      </label>
      <p class="text-red-500 text-xs italic" v-if="privacyPolicyError">{{ privacyPolicyError }}</p>
    </div>


    <div class="mb-4">
<div class="flex items-center justify-between">
  <button
    class="border-b-4 border-green-800 mt-2 text-sm sm:text-lg block w-full items-center px-8 py-3 bg-green-600 text-white rounded-2xl font-bold hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
    type="submit">
    GET CASH OFFER NOW→
  </button>
</div>
<!---<p class="mt-4 text-xs text-gray-500">
  By selecting 'Get Cash Offer Now→', you consent to being contacted by us to discuss your property.
</p> -->
</div>

    </form>
    <div class="mt-2 flex justify-center">
      <p class="text-gray-500 flex items-center">
        <i class="text-green-600 fas fa-shield-alt mr-1 text-sm items-center"></i>
        Safe, secure &amp; confidential
      </p>
    </div>
  </div>
</div>
</template>

       
<script>
import axios from "axios";

const dataSoapApiKey = "3c6673da8ed24dadaffd5f7663631f5ed731304b6d24412a9c80384090af46c7"; 


export default {
props: {
addressData: {
  type: Object,
  default: () => ({}),
},
gclid: {
      type: String,
      default: '',
},
quizData: {
  type: Object,
  default: () => ({}),
},
utmCampaign: {
  type: String,
  default: '',
},
utmSource: {
  type: String,
  default: '',
},
utmMedium: {
  type: String,
  default: '',
},
utmKeyword: {
  type: String,
  default: '',
},
utmDevice: {
  type: String,
  default: '',
},
utmMatchType: {
  type: String,
  default: '',
},
seoQuery: {
  type: String,
  default: '',
},
creativeId: {
  type: String,
  default: '',
},
},
name: "ContactForm",
data() {
  return {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    phoneError: "",
    messageError: "",
    privacyPolicy: false,
    privacyPolicyError: "",
    isSubmitting: false,
    submitSuccess: false,
    submitError: false,
    quizDataMapping: {
    apiParam1: "on_market",
    apiParam2: "prop_type",
    apiParam3: "beds",
    apiParam4: "reasons_for_sale",
    apiParam5: "speed_of_sale",
    phoneHlrStatus: null,  // This will store the active status
    country: "", // New property for the country
  },
  localUtmCampaign: this.utmCampaign,
  localUtmSource: this.utmSource,
  localUtmMedium: this.utmMedium,
  localUtmKeyword: this.utmKeyword,
  localUtmDevice: this.utmDevice,
  localUtmMatchType: this.utmMatchType,
  localAdId: this.adId,
  localAdSetId: this.adSetId,
  localSeoQuery: this.seoQuery,
  localCreativeId: this.creativeId,
  localGclid: this.gclid,
  };
},

  created() {
  if (this.localUtmCampaign) {
    localStorage.setItem("utm_campaign", this.localUtmCampaign);
  } else {
    const storedUtmCampaign = localStorage.getItem("utm_campaign");
    if (storedUtmCampaign) {
      this.localUtmCampaign = storedUtmCampaign;
    }
  }

  if (this.localGclid) {
      localStorage.setItem("gclid", this.localGclid);
    } else {
      const storedGclid = localStorage.getItem("gclid");
      if (storedGclid) {
        this.localGclid = storedGclid;
      }
    }

  if (this.localUtmSource) {
    localStorage.setItem("utm_source", this.localUtmSource);
  } else {
    const storedUtmSource = localStorage.getItem("utm_source");
    if (storedUtmSource) {
      this.localUtmSource = storedUtmSource;
    }
  }

  if (this.localUtmMedium) {
    localStorage.setItem("utm_medium", this.localUtmMedium);
  } else {
    const storedUtmMedium = localStorage.getItem("utm_medium");
    if (storedUtmMedium) {
      this.localUtmMedium = storedUtmMedium;
    }
  }

  if (this.localUtmKeyword) {
    localStorage.setItem("utm_keyword", this.localUtmKeyword);
  } else {
    const storedUtmKeyword = localStorage.getItem("utm_keyword");
    if (storedUtmKeyword) {
      this.localUtmKeyword = storedUtmKeyword;
    }
  }

  if (this.localUtmDevice) {
    localStorage.setItem("utm_device", this.localUtmDevice);
  } else {
    const storedUtmDevice = localStorage.getItem("utm_device");
    if (storedUtmDevice) {
      this.localUtmDevice = storedUtmDevice;
    }
  }

  if (this.localUtmMatchType) {
    localStorage.setItem("matchtype", this.localUtmMatchType);
  } else {
    const storedUtmMatchType = localStorage.getItem("matchtype");
    if (storedUtmMatchType) {
      this.localUtmMatchType= storedUtmMatchType;
    }
  }
  
  if (this.localSeoQuery) {
    localStorage.setItem("seo_query", this.localSeoQuery);
  } else {
    const storedSeoQuery = localStorage.getItem("seo_query");
    if (storedSeoQuery) {
      this.localSeoQuery = storedSeoQuery;
    }
  }

  if (this.localCreativeId) {
    localStorage.setItem("creativeid", this.localCreativeId);
  } else {
    const storedCreativeId = localStorage.getItem("utm_creativeid");
    if (storedCreativeId) {
      this.localCreativeId = storedCreativeId;
    }
  }

},




methods: {
  validateFirstName() {
    if (!this.firstName) {
      this.firstNameError = "First name is required";
    } else {
      this.firstNameError = "";
    }
  },
  validateLastName() {
    if (!this.lastName) {
      this.lastNameError = "Last name is required";
    } else {
      this.lastNameError = "";
    }
  },

  validateEmail() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!this.email) {
      this.emailError = "Email is required";
    } else if (!emailPattern.test(this.email)) {
      this.emailError = "Invalid email address";
    } else {
      this.emailError = "";
    }
  },


  async validatePhone() {
const phonePattern = /^(\+?\d{1,4}[-\s]?)?(\d{1,4}[-\s]?){1,7}\d{1,4}$/;
if (!this.phone) {
  this.phoneError = "Phone number is required";
} else if (!phonePattern.test(this.phone)) {
  this.phoneError = "Invalid phone number";
} else {
  try {
    const isValid = await this.validateMobileNumberWithDatasoap();
    if (isValid === false) {
      this.phoneError = "Invalid mobile number";
    } else {
      this.phoneError = ""; // <-- Set phoneError to an empty string
    }
  } catch (error) {
    console.error("Error validating mobile number with DataSoap:", error);
    // Fallback to original phone number validation
    this.phoneError = "";
  }
}
},




  validatePrivacyPolicy() {
    if (!this.privacyPolicy) {
      this.privacyPolicyError = "You must agree to the Privacy Policy";
    } else {
      this.privacyPolicyError = "";
    }
  },


  async validateMobileNumberWithDatasoap() {
  try {
    const response = await axios.post(
      'https://api.datasoap.co.uk/v2',
      {
        lookup: this.phone,
        checks: ['hlr']
      },
      {
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain',
          'Authorization': `Bearer ${dataSoapApiKey}`
        }
      }
    );
    console.log("DataSoap API response:", response.data);

    // Check if the phone number is syntactically valid
    if (response.data.valid === true) {
      // Set the phoneHlrStatus based on the active status of the phone number
      this.phoneHlrStatus = response.data.hlr.on ? 'active' : 'non-active';

      // Clear any previous error messages as the number is valid
      this.phoneError = "";

      // You can return true or the active status based on your requirement
      return true;
    } else {
      // Set error message if the phone number is not valid
      this.phoneError = 'Invalid mobile number';
      return false;
    }
  } catch (error) {
    console.error('Error validating mobile number with DataSoap:', error);
    // Fallback to original phone number validation
    const phonePattern = /^(\+?\d{1,4}[-\s]?)?(\d{1,4}[-\s]?){1,7}\d{1,4}$/;
    if (!this.phone || !phonePattern.test(this.phone)) {
      this.phoneError = "Invalid phone number";
      return false;
    } else {
      // Clear the error if the fallback validation passes
      this.phoneError = "";
      return true;
    }
  }
},



  async submitForm() {
    this.validateFirstName();
    this.validateLastName();
    this.validateEmail();
    await this.validatePhone();
    this.validatePrivacyPolicy();

    if (this.firstNameError || this.lastNameError || this.emailError || (this.phoneError !== null && this.phoneError !== undefined && this.phoneError !== '') || this.privacyPolicyError) {
      return;
    }

    this.isSubmitting = true;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const formData = new FormData();
      formData.append("lp_offer_id", "20");
      formData.append("lp_campaign_id", "649e7d026926f");
      formData.append("site_ref", "sellhousefastscotland.co.uk");
      formData.append("first_name", this.firstName);
      formData.append("last_name", this.lastName);
      formData.append("email_address", this.email);
      formData.append("phone_home", this.phone);
      formData.append("accepted_terms", this.privacyPolicy ? "1" : "0");
      if (this.addressData) {
      formData.append("address", this.addressData.street); // Update this line
      formData.append("postcode", this.addressData.postcode); // Update this line
      formData.append("phone_hlr", this.phoneHlrStatus);  // Append the HLR status
      formData.append("country", this.addressData.country);
    }

      // Append quizData fields to formData
      for (const key in this.quizData) {
        const apiParamName = this.quizDataMapping[key];
        if (apiParamName) {
          formData.append(apiParamName, this.quizData[key]);
        }
      }

      if (this.localUtmCampaign) {
      formData.append('utm_campaign', this.localUtmCampaign);
      console.log(`Appended utm_campaign to formData: ${this.localUtmCampaign}`);

     }


     if (this.localUtmSource) {
      formData.append('utm_source', this.localUtmSource);
      console.log(`Appended utm_source to formData: ${this.localUtmSource}`);
     }

     if (this.localUtmMedium) {
      formData.append('utm_medium', this.localUtmMedium);
      console.log(`Appended utm_medium to formData: ${this.localUtmMedium}`);
     }

     if (this.localUtmKeyword) {
      formData.append('utm_keyword', this.localUtmKeyword);
      console.log(`Appended utm_keyword to formData: ${this.localUtmKeyword}`);
     }

     if (this.localUtmDevice) {
      formData.append('utm_device', this.localUtmDevice);
      console.log(`Appended utm_device to formData: ${this.localUtmDevice}`);
     }

     if (this.localUtmMatchType) {
      formData.append('matchtype', this.localUtmMatchType);
      console.log(`Appended matchtype to formData: ${this.localUtmMatchType}`);
     }

     if (this.localSeoQuery) {
      formData.append('seo_query', this.localSeoQuery);
      console.log(`Appended seo_query to formData: ${this.localSeoQuery}`);
     }


     if (this.localCreativeId) {
      formData.append('creativeid', this.localCreativeId);
      console.log(`Appended creativeid to formData: ${this.localCreativeId}`);
     }

     if (this.localGclid) {
        formData.append('gclid', this.localGclid);
        console.log(`Appended gclid to formData: ${this.localGclid}`);
      }

     if (this.localUtmMatch) {
        formData.append('utm_matchtype', this.localUtmMatch);
        console.log(`Appended utm_matchtype to formData: ${this.localUtmMatch}`);
     }

      await axios.post("https://leadseeker.leadspediatrack.com/post.do", formData, config);

      this.submitSuccess = true;
      this.submitError = false;
    } catch (error) {
      this.submitSuccess = false;
      this.submitError = true;
      console.error("Error posting the form:", error);
    }

    this.isSubmitting = false;

    if (this.submitSuccess) {
        // At this point, we are sure that the lead score has been calculated,
        // and the form data has been successfully submitted.
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'leadSubmission',
          'lead_score': this.leadScore, // Make sure this is the correct and final value
          'email': this.email, // Make sure this.email contains the email value
          'gclid': this.gclid, // Make sure this.email contains the email value
        });

        // Redirect to the thank you page or display success message
        localStorage.setItem('userEmail', this.email);
        window.location.href = `/thank-you?email=${encodeURIComponent(this.email)}`;
  }
},
},
computed: {
isFormValid() {
  return (
    this.firstName &&
    this.lastName &&
    this.email &&
    this.phone &&
    this.privacyPolicy
    );
},
}
};
</script>

<style>
@media (max-width: 640px) {
.small-device-text {
  font-size: 0.525rem; /* Adjust the value to your preference */
}
}
</style>
