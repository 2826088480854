<template>
  <div class="relative bg-indigo-900">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-sign.jpg" alt="">
      <div class="absolute inset-0 bg-indigo-900 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">About Us</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">Aye, Welcome to Our Wee Family Business!
Imagine this: it’s 1998, the Spice Girls are telling us what we really, really want, and here we are, kicking off a family-run business in the heart of Scotland. We’re not just about buying properties; we’re about creating stories, meeting characters, and making friends. From the Borders to the Highlands and Islands, we’ve been on one hell of a journey!
      </p>

      <div class="mt-6 flex justify-center">
        <div class="w-96 h-64 rounded-lg overflow-hidden shadow-lg relative">
          <img src="@/assets/team-photo.jpg" alt="Team Photo" class="object-cover w-full h-full">
          <div class="absolute bottom-0 left-0 w-full bg-black bg-opacity-75 py-2 px-4 text-white text-center font-semibold">The Invest Team</div>
        </div>
      </div>

          <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-2xl pt-4">Our Journey  </h3>

        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
Way back in the day, when the internet was just a baby and Billy Connolly was cracking jokes about how things never quite worked out the way they should, 
we decided to start this venture. Our aim was simple: help folks sell their homes without the hassle, with a dash of honesty, and a sprinkle of good old Scottish charm. Over the years, our family’s grown, but we’ve stuck to our roots.
        </p>

        <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-2xl pt-4">Our Values</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
          Family-Run, Client-Focused: We’re big enough to handle a heap of properties, but small enough to care about each and every one of you. Those 10 out of 10 reviews aren’t just numbers to us; they’re proof that we’re doing right by you.
Transparency and Approachability: No funny business here. We tell it like it is. Got questions? We’ve got answers. Need a chat? We’re always here.
Always Here for You: Day or night, rain or shine, we’re here when you need us. Whether it’s a quick natter or a deep dive into your property needs, we’re your go-to folks..
        </p>


        <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-2xl pt-4">What We Do</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
          We buy properties. Simple as that. But it’s not just about the bricks and mortar; it’s about the people inside. Every home has a story, and we’ve been lucky enough to be a part of many. We’ve seen the cutest wee cottages to the grandest of manors. Our job? Make the selling process as smooth as a dram of fine whisky.
        </p>


        <h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl mr-4 lg:text-2xl pt-4"> Our Reach</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
From the hustle and bustle of Glasgow’s city streets to the quiet serenity of the Outer Hebrides, we’ve got you covered. Whether you're selling a flat in Edinburgh or a farmhouse in the Highlands, we’re here for you.
</p>

<h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl mr-4 lg:text-2xl pt-4"> Testimonials</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
Our clients? They’re our biggest fans. Don’t just take our word for it. Here’s what they’ve got to say:</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
"Selling my home was a breeze with these folks. Honest, caring, and efficient – couldn’t ask for better!" <br>

"From start to finish, they made us feel like part of the family."</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
So, if you’re thinking of selling, remember – with us, you’re not just another client. You’re part of our story.</p>

<h3 class="text-4xl font-bold tracking-tight text-white sm:text-5xl mr-4 lg:text-2xl pt-4"> Contact Us</h3>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">
Got a property to sell or just fancy a wee blether about your options? Give us a call, drop us an email, or pop by our office. We’re always here, ready with a cuppa and a chat.
</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
Thank you for considering us for your property needs. We can’t wait to meet you and add your story to our ever-growing journey.</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
Feel free to reach out anytime. We’re always up for a blether and ready to help you with your property needs.</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
We hope you enjoyed reading about us as much as we enjoyed sharing our story with you. Here’s to many more years of serving the wonderful people of Scotland!
</p>
     
     <a href="/#addressForm" class="bg-green-500 hover:bg-green-600 text-white font-bold text-center py-2 px-4 rounded shadow-lg mt-6 mx-auto text-xl block max-w-xs">Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i></a>

    </div>
  </div>
</template>







<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>
export default {
  name: 'AboutUs',
  metaInfo() {
    return {
      title: 'About Us | Sell House Fast Scotland',
      meta: [
        {
          name: 'description',
          content: 'All about Sell House Fast Scotland - Get a Free Cash Offer Today - A Team with 30 years experience.',
        },
        {
          name: 'keywords',
          content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
        },
      ],
    };
  },
};
</script>
  

  