<template>
    <div class="relative bg-indigo-900">
      <div class="absolute inset-0">
        <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-sign.jpg" alt="">
        <div class="absolute inset-0 bg-indigo-900 mix-blend-multiply" aria-hidden="true"></div>
      </div>
      <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">404 - Page Not Found</h1>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">Oops! The page you're looking for does not exist or is currently being updated.</p>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">Please check the URL or go back to the homepage.</p>
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
          Go to Homepage <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </template>
  
  <style>
  .opacity-50 {
    opacity: 0.3;
  }
  </style>
  
  <script>
  export default {
    name: 'NotFound',
    metaInfo() {
      return {
        title: '404 - Page Not Found',
        meta: [
          {
            name: 'description',
            content: 'Oops! The page you\'re looking for does not exist.',
          },
          {
            name: 'keywords',
            content: '404, page not found',
          },
        ],
      };
    },
  };
  </script>
  